import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { IconNumber0, IconNumber5, IconZeroGreen } from "../Graphics";
import { ErrorNumbers } from "./ErrorNumbers";
import { ErrorPageContent } from "./ErrorPageContent";

export function Error500Content(): ReactElement {
  const { t } = useTranslation("features.errorPages");

  return (
    <ErrorPageContent
      graphic={
        <ErrorNumbers>
          <IconNumber5 />
          <IconZeroGreen />
          <IconNumber0 />
        </ErrorNumbers>
      }
      title={t("Error500.title")}
      description={t("Error500.description")}
      buttonTitle={t("Error500.buttonTitle")}
      onButtonClick={() => (window.location.href = "/")}
    />
  );
}
