import { isAxiosError } from "axios";

import { PlatformRequestError } from "../types/platformRequestError";

export function isPlatformRequestError(
  err: unknown,
  status = 400,
  error?: string,
  ...errorCodes: string[]
): err is PlatformRequestError {
  if (isAxiosError(err)) {
    if (err.response?.status !== status) {
      return false;
    }

    if (error !== undefined && err.response?.data?.error !== error) {
      return false;
    }

    if (errorCodes.length === 0) {
      return true;
    }

    if (errorCodes.includes(err.response?.data?.errorCode)) {
      return true;
    }
  }

  return false;
}
