import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import React from "react";

import { SplashError } from "./Layout/SplashError";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = AppInsightsContext;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });

    console.error(error);
    console.log(errorInfo);

    const appInsights = this.context as ApplicationInsights;

    appInsights &&
      appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: errorInfo,
      });
  }

  render() {
    if (this.state.error !== null) {
      return <SplashError error={this.state.error} />;
    }

    return this.props.children;
  }
}
