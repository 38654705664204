import { AccountInfo, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const MsalDataContext = createContext(null);

export function useMsalData() {
  return useContext(MsalDataContext);
}

export function MsalAccountProvider({ children }: { children: ReactNode }) {
  const { instance } = useMsal();
  const [msalData, setMsalData] = useState<AccountInfo | null>(null);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        instance.setActiveAccount((event.payload as FIXME).account);
        setMsalData((event.payload as FIXME).account);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, setMsalData]);

  const value = useMemo(
    () => ({
      msalData,
      setMsalData,
    }),
    [msalData, setMsalData]
  );

  return (
    <MsalDataContext.Provider value={value}>
      {children}
    </MsalDataContext.Provider>
  );
}
