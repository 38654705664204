import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";

import { API_ENDPOINT_PREFIX, COMMIT_ID, DEV_BAR_COLOR } from "./AppConfig";
import AppRoutes from "./AppRoutes";
import { MsalAccountProvider } from "./Context/MsalAccount/MsalAccountProvider";
import { CustomNavigationClient } from "./Context/UserAuth/msal/CustomNavigationClient";
import { DevBar } from "./Features/DevBar";
import { SnackbarProvider } from "./Features/Snackbar";

type Props = {
  pca: PublicClientApplication;
};

function App({ pca }: Props) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <MsalAccountProvider>
        <DevBar
          color={DEV_BAR_COLOR}
          commitId={COMMIT_ID}
          apiEndpointPrefix={API_ENDPOINT_PREFIX}
        />
        <SnackbarProvider>
          <CssBaseline />
          <AppRoutes />
        </SnackbarProvider>
      </MsalAccountProvider>
    </MsalProvider>
  );
}

export default App;
