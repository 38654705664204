import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";

import {
  ADB2C_APPLICATION_ID,
  ADB2C_CZEM_SMS_LOGIN_POLICY,
  ADB2C_INSTANCE,
  ADB2C_INVITATION_POLICY,
  ADB2C_MFA_RENEW_POLICY,
  ADB2C_POLICY,
  ADB2C_REDIRECT_URI,
  ADB2C_SIGNUP_POLICY,
  ADB2C_TENANT,
} from "../../AppConfig";

export function makeMsalConfig(): Configuration {
  return {
    auth: {
      clientId: ADB2C_APPLICATION_ID,
      redirectUri: ADB2C_REDIRECT_URI,
      postLogoutRedirectUri: ADB2C_REDIRECT_URI,
      authority: `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_POLICY}`,
      knownAuthorities: [
        `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_POLICY}`,
        `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_SIGNUP_POLICY}`,
        `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_INVITATION_POLICY}`,
        `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_MFA_RENEW_POLICY}`,
        `https://${ADB2C_INSTANCE}/${ADB2C_TENANT}/${ADB2C_CZEM_SMS_LOGIN_POLICY}`,
      ],
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      // since our access token has lifetime of 5 minutes,
      // renew it 2 minutes before it expires
      tokenRenewalOffsetSeconds: 60 * 2,
    },
  };
}
