import { IconCloudOff } from "@dfp/icons";
import { useTranslation } from "react-i18next";

import { ErrorPageContent } from "./ErrorPageContent";

export function ErrorUnreachableServerContent() {
  // Use errors namespaces as it is always preloaded, in the case of network error we can't download any other translations
  const { t } = useTranslation("errors");

  return (
    <ErrorPageContent
      graphic={<IconCloudOff height={64} width={64} />}
      title={t("serverUnreachable")}
      description={t("serverUnreachableLong")}
    />
  );
}
