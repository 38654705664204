import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { DEFAULT_LANGUAGE } from "../../AppConfig";
import { AppLanguage, LANGUAGES } from "../../I18n/languages";

type AppLanguageContext = {
  lang: AppLanguage;
  setLang: (lang: AppLanguage) => void;
};

const AppLanguageContext = createContext<AppLanguageContext>(null);

export const useAppLanguageContext = () => useContext(AppLanguageContext);

type Props = {
  children: React.ReactNode;
};

export function AppLanguageProvider({ children }: Props) {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const paramLang = searchParams.get("lang");

  const [lang, setLanguage] = useState<AppLanguage>(
    getValidAppLanguage(paramLang)
  );

  useEffect(() => {
    document.documentElement.lang = lang;
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const contextValue = useMemo(
    () => ({
      lang,
      setLang: (lang: AppLanguage) => {
        if (paramLang !== undefined && paramLang !== null) {
          console.warn(`Overriding saved language by: '${paramLang}.`);
          return;
        }
        setLanguage(lang);
      },
    }),
    [lang, setLanguage, paramLang]
  );

  return (
    <AppLanguageContext.Provider value={contextValue}>
      {children}
    </AppLanguageContext.Provider>
  );
}

function getValidAppLanguage(lang: string): AppLanguage {
  if (LANGUAGES.includes(lang)) {
    return lang as AppLanguage;
  }

  return DEFAULT_LANGUAGE as AppLanguage;
}
