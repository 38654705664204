import type { SVGProps } from "react";

const SvgZeroGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="80"
    fill="none"
    viewBox="0 0 60 80"
    {...props}
  >
    <path
      d="M24.7406 0.293969C17.2388 1.2794 -6.64816 6.5873 1.77459 50.0247C6.8844 76.385 23.8198 81.2561 35.2298 79.7556C46.6398 78.255 61.7446 69.1846 59.8355 42.3988C56.7359 -1.72168 32.2874 -0.691462 24.7406 0.293969ZM39.4973 41.962C41.1145 55.7245 36.7571 59.9461 32.6917 60.4725C28.6151 61.01 23.3144 58.0537 21.3154 44.3472C18.0137 21.7831 24.5947 19.7898 27.2675 19.4315C29.9403 19.0732 36.8582 19.3195 39.531 41.962H39.4973Z"
      fill="#CBD733"
    />
  </svg>
);
export default SvgZeroGreen;
