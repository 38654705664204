import type { SVGProps } from "react";

const SvgFive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="96"
    fill="none"
    viewBox="0 0 57 96"
    {...props}
  >
    <path
      d="M3.27942 6.438L3.27696 6.47391L1.38449 49.1371V49.2034C1.38449 50.8855 1.80433 52.6923 3.161 54.0577C4.51766 55.4231 6.31302 55.8456 7.98441 55.8456H11.5086C12.6987 55.8456 13.9408 55.6228 15.0594 54.9733C16.2234 54.2975 17.0424 53.2808 17.5199 52.0793L17.5654 51.965L17.6013 51.8472C18.4087 49.1972 19.6894 47.4336 21.3303 46.2973C22.9964 45.1436 25.3114 44.4453 28.5 44.4453C32.3119 44.4453 35.0124 45.5789 36.9537 47.5956L36.97 47.6126L36.9866 47.6292C38.8803 49.5351 40.1502 52.9062 40.1502 58.4503V65.6705C40.1502 70.9673 39.002 74.3857 37.2197 76.4577C35.5079 78.4478 32.7922 79.6754 28.5 79.6754C24.0945 79.6754 21.3211 78.6116 19.6354 76.9969C17.9041 75.1732 16.9757 72.8301 16.9757 69.7239V69.3439C16.9757 67.7594 16.561 66.0326 15.2224 64.7353C13.9046 63.4581 12.1877 63.0816 10.6275 63.0816H6.72578C5.06111 63.0816 3.2633 63.4684 1.8811 64.7668C0.460561 66.1011 0 67.9029 0 69.5972V69.9772C0 77.6421 2.59073 84.0478 7.88802 88.8944L7.8978 88.9034L7.90766 88.9122C13.2623 93.7238 20.2265 96 28.5 96C36.8171 96 43.7751 93.5699 49.0487 88.4554L49.0494 88.4548C54.4777 83.1868 57 75.8191 57 66.8105V56.6769C57 47.9054 54.726 40.7234 49.7473 35.608C44.9117 30.5527 38.521 28.1207 30.8914 28.1207C26.2496 28.1207 22.1232 29.0179 18.6294 30.9012L19.2436 16.4513H48.3863C50.0577 16.4513 51.853 16.0287 53.2097 14.6634C54.5664 13.298 54.9862 11.4911 54.9862 9.80901V6.64226C54.9862 4.96016 54.5664 3.15328 53.2097 1.78791C51.853 0.422539 50.0577 0 48.3863 0H10.1241C8.43697 0 6.679 0.428718 5.30838 1.71914C3.93886 3.00852 3.39545 4.74466 3.27942 6.438Z"
      fill="#C4C4C4"
    />
  </svg>
);
export default SvgFive;
