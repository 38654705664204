import { Box, Button, Typography } from "@mui/material";
import type { ReactElement, ReactNode } from "react";

type ErrorPageContentType = {
  graphic: ReactNode;
  title: string;
  description: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
};

export function ErrorPageContent({
  graphic,
  title,
  description,
  buttonTitle,
  onButtonClick,
}: ErrorPageContentType): ReactElement {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: 3,
        gap: 3,
      }}
    >
      <Box>{graphic}</Box>
      <Box sx={{ textAlign: "center", maxWidth: 620 }}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      </Box>
      {buttonTitle ? (
        <Box>
          <Button variant="contained" color="primary" onClick={onButtonClick}>
            {buttonTitle}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
