import type { SVGProps } from "react";

const SvgFour = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65"
    height="96"
    fill="none"
    viewBox="0 0 65 96"
    {...props}
  >
    <path
      d="M26.8674 4.59373L1.65965 59.7873L1.63596 59.8455C0.636715 62.299 0 64.6081 0 66.6376V70.2367C0 71.9435 0.428609 73.777 1.8136 75.1625C3.19859 76.548 5.03143 76.9767 6.73771 76.9767H38.9325V89.2599C38.9325 90.9668 39.3612 92.8003 40.7461 94.1858C42.1311 95.5712 43.964 96 45.6702 96H49.3965C51.1027 96 52.9356 95.5712 54.3206 94.1858C55.7056 92.8003 56.1342 90.9668 56.1342 89.2599V76.9767H58.2623C59.9686 76.9767 61.8014 76.548 63.1864 75.1625C64.5714 73.777 65 71.9435 65 70.2367V67.0233C65 65.3164 64.5714 63.4829 63.1864 62.0974C61.8014 60.7119 59.9686 60.2832 58.2623 60.2832H56.1342V31.676C56.1342 29.9691 55.7056 28.1356 54.3206 26.7501C52.9356 25.3647 51.1027 24.9359 49.3965 24.9359H45.6702C43.964 24.9359 42.1311 25.3647 40.7461 26.7501C39.3612 28.1356 38.9325 29.9691 38.9325 31.676V60.2832H19.9127L43.6504 8.51666L43.6521 8.51303C44.4311 6.82249 44.6853 4.8456 43.7382 2.95065C43.277 2.02808 42.5695 1.235 41.6077 0.700446C40.6721 0.180513 39.6684 0 38.7318 0H33.9776C32.4785 0 31.0073 0.348949 29.7076 1.19025C28.4101 2.03009 27.4886 3.22771 26.8687 4.59089L26.8674 4.59373Z"
      fill="#C4C4C4"
    />
  </svg>
);
export default SvgFour;
