import type { SVGProps } from "react";

const SvgZero = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57"
    height="96"
    fill="none"
    viewBox="0 0 57 96"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.0295 88.5816L49.0395 88.5722L49.0494 88.5627C54.4777 83.3701 57 76.1078 57 67.2281V28.7719C57 19.9008 54.4819 12.6684 49.0397 7.55289C43.6998 2.44979 36.7554 0 28.5 0C20.2448 0 13.3006 2.44967 7.96068 7.5525C2.51828 12.6681 0 19.9006 0 28.7719V67.2281C0 76.1078 2.52229 83.3701 7.95057 88.5627L7.96049 88.5722L7.97049 88.5816C13.3142 93.5986 20.2551 96 28.5 96C36.7449 96 43.6858 93.5986 49.0295 88.5816ZM16.9757 66.1044C16.9757 71.5741 18.2063 74.9075 20.0304 76.7874C22.0769 78.8088 24.8029 79.909 28.5 79.909C32.3393 79.909 35.0509 78.8157 36.9866 76.8955C38.8738 75.0233 40.1502 71.6666 40.1502 66.1044V29.8956C40.1502 24.7206 39.004 21.3858 37.2269 19.3646C35.5171 17.4198 32.799 16.2159 28.5 16.2159C24.2508 16.2159 21.5669 17.4149 19.8758 19.3551C18.1137 21.3767 16.9757 24.7155 16.9757 29.8956V66.1044Z"
      fill="#C4C4C4"
    />
  </svg>
);
export default SvgZero;
