import { Box } from "@mui/material";
import type { ReactElement, ReactNode } from "react";

type ErrorNumbersProps = {
  children: ReactNode;
};

export function ErrorNumbers({ children }: ErrorNumbersProps): ReactElement {
  return (
    <Box sx={{ display: "flex", gap: 1.5, mb: 2, alignItems: "center" }}>
      {children}
    </Box>
  );
}
