import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  DistributedTracingModes,
  ITelemetryItem,
} from "@microsoft/applicationinsights-web";

import { API_BASE_URI, APP_INSIGHTS_CONNECTION_STRING } from "../../AppConfig";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  // https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-sdk-configuration
  config: {
    connectionString: APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    // enable distributed tracing correlation with API
    enableCorsCorrelation: true,
    correlationHeaderDomains: [new URL(API_BASE_URI).host],
    disableTelemetry:
      APP_INSIGHTS_CONNECTION_STRING === "InstrumentationKey=none;",
    isBeaconApiDisabled: true,
  },
});

appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags["ai.cloud.role"] = "FX Frontend";
});

export { appInsights, reactPlugin };
