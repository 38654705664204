import React from "react";

export const AccountLazy = React.lazy(
  () => import(/* webpackChunkName: "app-account" */ "./Account/AccountApp")
);
export const FoxLazy = React.lazy(
  () => import(/* webpackChunkName: "app-fox" */ "./Fox/FoxApp")
);
export const RootLazy = React.lazy(
  () => import(/* webpackChunkName: "app-root" */ "./Root/RootApp")
);
export const OpsLazy = React.lazy(
  () => import(/* webpackChunkName: "app-ops" */ "./Ops/OpsApp")
);
export const ManageLazy = React.lazy(
  () => import(/* webpackChunkName: "app-manage" */ "./Manage/ManageApp")
);
export const SignupLazy = React.lazy(
  () => import(/* webpackChunkName: "app-signup" */ "./SignupApp")
);
export const RegisterLazy = React.lazy(
  () => import(/* webpackChunkName: "app-register" */ "./RegisterApp")
);
export const MfaRenewLazy = React.lazy(
  () => import(/* webpackChunkName: "app-mfa-renew" */ "./MfaRenewApp")
);
export const OnboardingLazy = React.lazy(
  () =>
    import(
      /* webpackChunkName: "app-onboarding" */ "./Onboarding/OnboardingApp"
    )
);
export const JoinLazy = React.lazy(
  () => import(/* webpackChunkName: "app-join" */ "./Join/JoinApp")
);

export const VisitLazy = React.lazy(
  () => import(/* webpackChunkName: "app-visit" */ "./Visit/VisitApp")
);

export const KYCLazy = React.lazy(
  () => import(/* webpackChunkName: "app-kyc" */ "./KYC/KYCApp")
);

export const BankIdLazy = React.lazy(
  () => import(/* webpackChunkName: "app-bankId" */ "./BankId/BankIdApp")
);

export const PageNotFoundLazy = React.lazy(
  () => import(/* webpackChunkName: "app-page-not-found" */ "./PageNotFound")
);
