import { TOptions } from "i18next";

export class TError extends Error {
  public key: string;
  public options: TOptions;

  /**
   * Throw this when there is an error in the request structure or data
   * e.g. requested document not found, request validation error, business rule error
   *
   * @param key translation key
   * @param namespace translation namespace
   * @param [options] same options that go to i18next's t() function
   */
  constructor(key: string, namespace: string, options?: TOptions) {
    super();

    this.name = "TError";
    this.key = key;
    this.options = {
      ...options,
      ns: namespace,
    };
  }
}
