import type { SVGProps } from "react";

const SvgOne = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="96"
    fill="none"
    viewBox="0 0 49 96"
    {...props}
  >
    <path
      d="M17.0688 0.705029L5.45686 2.89643L5.37158 2.91773C3.91378 3.28185 2.5009 3.98559 1.46423 5.23712C0.409071 6.51095 0 8.04238 0 9.56787V12.01C0 13.5884 0.396982 15.3255 1.71938 16.6467C3.04178 17.9679 4.78043 18.3645 6.36019 18.3645H15.8241V79.435H6.74614C5.06371 79.435 3.24336 79.8394 1.8536 81.18C0.444149 82.5397 0 84.3551 0 86.0465V89.3885C0 91.0799 0.444149 92.8953 1.8536 94.2549C3.24336 95.5956 5.06371 96 6.74614 96H42.2539C43.9363 96 45.7566 95.5956 47.1464 94.2549C48.5559 92.8953 49 91.0799 49 89.3885V86.0465C49 84.3551 48.5559 82.5397 47.1464 81.18C45.7566 79.8394 43.9363 79.435 42.2539 79.435H33.1759V6.74009C33.1759 5.00676 32.7216 3.15967 31.2914 1.77845C29.8803 0.415588 28.0273 0 26.3011 0H23.4708C21.2969 0 19.1614 0.234222 17.0688 0.705029Z"
      fill="#C4C4C4"
    />
  </svg>
);
export default SvgOne;
