import { type CSSProperties, useState } from "react";

type Props = {
  apiEndpointPrefix: string | undefined;
  commitId: string;
  color: string | undefined;
};
export function DevBar({ apiEndpointPrefix, commitId, color }: Props) {
  const [open, setOpen] = useState(true);

  if (!color || !open) {
    return null;
  }

  return (
    <div style={getOuterStyle(color)}>
      <div>{apiEndpointPrefix}</div>
      <div>
        {commitId === "local build" ? (
          commitId
        ) : (
          <a
            style={commitStyle}
            href={`https://bitbucket.org/directpojistovna/fidoo-fx-fe/commits/${commitId}`}
            target="_blank"
            rel="noreferrer"
          >
            commit
          </a>
        )}
      </div>
      <div style={xStyle} onClick={() => setOpen(false)}>
        x
      </div>
    </div>
  );
}

const getOuterStyle = (color: string): CSSProperties => ({
  position: "fixed",
  zIndex: 1000000,
  height: "15px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr max-content max-content",
  gap: "10px",
  padding: "1px 4px",
  color: "white",
  fontSize: "12px",
  lineHeight: "12px",
  fontWeight: "bold",
  background: color,
});

const xStyle: CSSProperties = {
  cursor: "pointer",
};

const commitStyle: CSSProperties = {
  color: "white",
  textDecoration: "none",
};
