import { Box, BoxProps, styled } from "@mui/material";
import { keyframes } from "@mui/system";

type Props = {
  color?: string;
} & BoxProps;

export function LoadingIndicator({
  color = "rgba(0, 0, 0, 0.26)",
  ...rest
}: Props) {
  return (
    <Wrapper {...rest}>
      <IndicatorDot color={color} sx={{ animationDelay: "-.4s" }} />
      <IndicatorDot color={color} sx={{ animationDelay: "-.2s" }} />
      <IndicatorDot color={color} />
    </Wrapper>
  );
}

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: "inherit",
  gap: "0.5em",
}));

const IndicatorDotKeyframes = keyframes`
0%, 80%, 100% {
  transform: scale(0);
}
40% {
  transform: scale(1);
}
`;

const IndicatorDot = styled("div", {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "sx",
})((props) => ({
  display: "inline-block",
  width: "0.7em",
  height: "0.7em",
  backgroundColor: props.color ?? "rgba(0, 0, 0, 0.26)",
  borderRadius: "50%",
  animationTimingFunction: "ease-in-out",
  animationIterationCount: "infinite",
  animationFillMode: "both",
  animationDuration: "1.2s",
  animationName: IndicatorDotKeyframes,
}));
