import { ENABLE_DEV_LANG } from "../AppConfig";

export const languages = ["en", "cs"] as const;

const languagesWithDev = [...languages, "dev", "cimode"] as const;

export const LANGUAGES =
  ENABLE_DEV_LANG === "yes" ? languagesWithDev : languages;

export type AppLanguage = (typeof LANGUAGES)[number];
