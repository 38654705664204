import { PublicClientApplication } from "@azure/msal-browser";
import { theme } from "@dfp/mui-theme";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { AppLanguageProvider } from "./Context/AppLanguage";
import { reactPlugin } from "./Features/AppInsights/AppInsightsService";
import { makeMsalConfig } from "./Features/MSAuthLibrary/makeMsalConfig";
import { ReplicatedHistoryProvider } from "./Features/ReplicatedHistory";
import "./I18n/i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

function initializeMsalInstance(msalInstance: PublicClientApplication) {
  msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]); //
    }

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <ReplicatedHistoryProvider>
                  <AppLanguageProvider>
                    <App pca={msalInstance} />
                  </AppLanguageProvider>
                </ReplicatedHistoryProvider>
              </BrowserRouter>
            </ThemeProvider>
          </ErrorBoundary>
        </AppInsightsContext.Provider>
      </React.StrictMode>
    );
  });
}

const msalInstance = new PublicClientApplication(makeMsalConfig());
initializeMsalInstance(msalInstance);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
